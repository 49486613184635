.report-button{
    width:100%;
    height: 100%;
    padding: 20px;
    border-radius: 10px;
}

.background-controll{
    background-repeat: no-repeat !important;
    background-position: bottom right !important;
    background-size: 20% !important;

    p{
        color: #fff;
        font-size: 1em;
    }

    &:hover{
        cursor: pointer;
    }
}

.gene{
    background-color: #007bff;
}

.alumnos{
    height: 150px !important;
    background: #20c997;  
}

.instancias{
    height: 150px !important;
    background: #f17c88;
}

.materias{
    height: 175px !important;
    background: #6c757d;
}
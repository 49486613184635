.foto-container {
    position: relative;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    background-color: #ccc;
    overflow: visible;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
.selected-foto{
    width: 170px;
    height: 170px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
}

.btn-container-foto {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 25px;
    color: #fff;
    background-color: #025692;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.no-view{
    display: none !important;
}

.isSelectedCuit{
    border: 1px solid #025692;
    border-radius: 15px;
    padding: 5px;
    font-size: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    *{
      color: #171717;
      font-size: 14px;
      margin: auto 0;
    }
}

.drag-drop-modal-box{

    padding: 5px;
    display: flex;
    border-bottom: 1px solid #025692;
    margin-bottom: 10px;
    *{
      color: #025692;
      margin: 0 10px 0 0;
    }
}

.image-preview{
    width: 50%;
    height: 50%;
    object-fit: fill;
}


.add-study{
  display: inline;
  margin: 15px 0px;
  padding: 8px;

  .add-estudy-span{
    margin: 0px 10px;
  }

  &:hover{
    background-color: #025692;
    color: #fff;
    transition: 1s all ease-in-out;
    padding: 8px;
    border-radius: 10px;
    cursor: pointer;
  }
}

.del-study{
  color: #fff;
  background-color: #d83f3f;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  border-radius: 10px;
  cursor: pointer;
}

.study-box{
  border-bottom: 1px solid #9b9b9b;
}

.btn-vermotivoRechazo{
  background-color: #fff;
  color: #000;
  padding: 4px;
  border-radius: 10px;
  border:none;
}

.motivo-rechazo {
  margin: 10px 0 10px 5px;
}

.otros-estudios__container{
  display: flex;
  flex-direction: column;
  background-color: #025692;
  border-radius: 10px;
  padding: 10px;
  margin-left: 5px;

  p{
    color: #fff;
    font-size: 16px;
  }

  .subparagraph{
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }


  .otros-estudios-container__desc-box{
    display: flex;
    flex-direction: column;
    line-height: 0.5;
    margin: 5px 0px;
    p{
      font-size: 14px;
      font-style: italic;
      color: #fff;
      display: inline-block;
    }
  }

  .otros-estudios-container__header{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}


//CHECKBOX FXS
.transicion{
  opacity: 0;
  animation: aparecer 1s ease-in-out forwards;
}

.lang-box{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  background-color: #025692;
  color: #fff;
  border-radius: 10px;
  margin: 10px 20px;


  p{
    margin: 0 3px 0 0;
  }
}



//Loader Styles
.loader-img{
  height: 10%;
  width: 10%;
}

.loading-text
{
  font-size: 1.5em;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}


.items-spacing{
  margin: 10px 0;
}



.inputfile-1 + label {
  color: #fff;
  background-color: #025692;
}

.inputfile-1:focus + label,
.inputfile-1.has-focus + label,
.inputfile-1 + label:hover {
  background-color: #9f8465;
}
.container-input {
  text-align: center;
  background: #ffffff;
  border-top: 5px solid #025692;
  border: 1px dashed #025692;
  padding: 25px 0;
  border-radius: 6px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  max-width: 80%;
  font-size: 1.25rem;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
}

.inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  margin-right: 0.25em;
}

.iborrainputfile {
font-size:16px; 
font-weight:normal;
font-family: 'Lato';
}

.btn-save-discapacidad{
  background: #025692;
  padding: 10px;
  margin-top: 22px;
  border-radius: 10px;

  span{
    color: #fff;
  }

  &:hover{
    background-color: #3189c8;
    cursor: pointer;
  }
}


@media screen and (max-width: 768px){
  .btn-save-discapacidad{
    margin-top: 0;
    margin-left: 10px;
  }
}

//ANIMATIONS
@keyframes aparecer {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.carrer-card{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 10px;
    overflow: hidden;
    z-index: 0;
    .carrer-title{
        display: flex;
        flex: 70%;
        justify-content: flex-start;
        align-items: center;
        margin-left: 10px;
        transition: transform 0.3s ease-in-out;
        span {
            z-index: 2;
            font-size: 1rem;
            margin-top: 10px;
            background-color: #f2f2f2;
            color: #333;
            padding: 10px;
            border-radius: 5px;
            box-shadow: 3px 2px 15px 0px rgba(0, 0, 0, 0.1);
        }
    }

    .carrer-img{
        z-index: 2;
        margin-top: 25px;
        flex: 30%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        
        img{
            display: inline-block;
            height: 100%;
            width: 35%;
            margin-right: 10px;
        }
    }


    &:hover{
        cursor: pointer;

        .carrer-title {
            z-index: 2;
            transform: translateY(-13px);
            transition: 1s all ease;
          }
      

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            padding: 15px;
            transform: translate(-50%, -50%);
            background-color: #a4c6e9;
            border-radius: 50%;
            animation: bckgExpand 0.3s ease-in-out forwards;
            z-index: 1;
          }

    }

}

.tc_1{
    background-color: #025692;
}

.tc_3{
    background-color: #b2dfbb;
}

.tc_5{
    background-color: #fbdca2;
}

.tc_4{
    background-color: #f7b7b7;
}

.tc_2 {
    background-color: #f7bad0;
}

.custom-modal{
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    background-color: #ebebeb;
    z-index: 9999;
    animation: modalExpand 0.2s ease-in-out forwards;
}

.custom-modal-title{
    background-color:#025692;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    border-radius: 10px 10px 0 0;
    margin-bottom: 25px;
    *{
        color: #fff;
        margin: auto 10px;
    }

    span{
        font-size: 25px;
    }
}

.custom-modal-body{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.custom-continue-to-root{
    margin: 15px auto;
    
    button{
        background-color: #1BC5BD !important;
        color: #fff;

    }
}

.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Ajusta la opacidad según desees */
    z-index: 9998;
}

.circle-svg{
    border: 3px solid #007bff;
    border-radius: 50%;
    padding: 50px;
    margin: 0 auto;
    display: inline-block;
}

//Animations
@keyframes bckgExpand {
    0%{
        height: 0;
        width: 0;
        border-radius: 50%;
    }
    100%{
        border-radius: 10px;
        width: 100%;
        height: 100%;
    }
}

@keyframes modalExpand {
    0%{
        height: 0;
        width: 0;
        border-radius: 50%;
    }
    100%{
        border-radius: 10px;
        width: 50%;
        height: auto;
    }
}
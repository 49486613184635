
.card-notas{
    padding: 25px;
    height: 200px;
    margin: 0 5px;
    border-radius: 10px;
    width: 100% !important;
    p{
        font-size: 18px;
        color: #fff;
        margin: 15px 0 0 10px;
    }

}

.custom-modal-body{
  max-height: 600px;
  overflow-y: scroll;
}

.custom-header{
    background: #215c9c;
}

.carrer-container{
  position: relative;
  background: #ffbd76;
  border-radius: 10px;
  z-index: 2;
  
  .box-title{
    h4{
      color: #333;
      display: inline-block;
      background: #ffffff;
      border-radius: 5px;
      padding: 10px;
    }

    .box-descriptive{
      display: flex;
      flex-direction: column;
      margin-top: auto;
      justify-content: center;
      margin-top: 10px;
      .box-title__item_descriptive{
        color: #fff;
        padding: 5px 0;
      }
      span{
        font-size: 14px !important;
      }
    }

  z-index: 2;
  }

  .box-img{
    z-index: 2;
  }

  
  .inscribirme-text {
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    margin-right: 10px;
    display: inline-block;
    z-index: 2;

  }

  &:hover{
    cursor: pointer;

    .box-title{
      transform: translateY(-8px);
      transition: 0.5s all ease;
    }


    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 15px;
      transform: translate(-50%, -50%);
      background-color: #a96823;
      border-radius: 50%;
      animation: bckgExpand 0.3s ease-in-out forwards;
      z-index: 1;
    }
  }
}


.modalBody {
-ms-overflow-style: none;
scrollbar-width: none;
}


.carrer-container:hover 

.custon-title{
    color: #fff !important;
}

.excl{
    position: relative;
    background: #20c997;
    background-repeat: no-repeat;
    background-position: bottom right !important;
    background-size: 100px;
}

.manual{
    position: relative;
    background: #007bff;
    background-repeat: no-repeat;
    background-position: bottom right !important;
    background-size: 100px;
}

.excl:hover,
.manual:hover{
    background-size: 120px;
    background-position: top center !important;
    transition: 1s all ease;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  
    p {
      margin-top: 0;
      transition: 1.2s all ease;
      transform: translateY(100%);
    }
  
    &:hover p {
      margin-top: 90%;
      transform: translateY(0);
    }
}


//MODAL INSTANCIAS -> INSCRIPTOS

.option-box{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 10px;
  overflow: hidden;
  z-index: 0;
  .option-box__title{
      display: flex;
      flex: 70%;
      justify-content: flex-start;
      align-items: center;
      margin-left: 10px;
      transition: transform 0.3s ease-in-out;
      span {
          z-index: 2;
          font-size: 1rem;
          margin-top: 10px;
          background-color: #f2f2f2;
          color: #333;
          padding: 10px;
          border-radius: 5px;
          box-shadow: 3px 2px 15px 0px rgba(0, 0, 0, 0.1);
      }
  }

  .option-box__img{
      z-index: 6 !important;
      margin-top: 25px;
      flex: 30%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      
      img{
          display: inline-block;
          height: 100%;
          width: 20%;
          margin-right: 10px;
      }
  }

  .option-box__img-two{
    z-index: 6 !important;
    margin-top: 25px;
    flex: 30%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    
    img{
        display: inline-block;
        height: 100%;
        width: 13%;
        margin-right: 10px;
    }
  }


  &:hover{
      cursor: pointer;

      .option-box__title {
          z-index: 2;
          transform: translateY(-13px);
          transition: 1s all ease;
        }
    

      &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          padding: 15px;
          transform: translate(-50%, -50%);
          background-color: #a4c6e9;
          border-radius: 50%;
          animation: bckgExpand 0.3s ease-in-out forwards;
          z-index: 1;
        }

  }

}

//MODAL INIT WIZARD

.body-description{
    background: #e5efff !important;
    position: relative;
    flex: 1 1 auto;
}

.padder{
    padding: 30px;
}

.header-description{
    color: #007bff;
}

.inner-body{
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    margin: 20px 0;

    .red-remarker{
        color: #df4a4a;
    }


    .custom-unorderer{
        display: block;
        margin-left: -10px;

        li{
            display: block;
            position: relative;
        
            &:not(:last-child){
                margin-bottom: 16px;
            }

            &::before{
                content: "";
                position: absolute;
                top: 1.2em;
                left: -30px;
                margin-top: -.9em;
                background: linear-gradient(to right, #EEE5FF, #007bff);
                height: 12px;
                width: 12px;
                width: 12px;
                border-radius: 50%;
            }
        }
    }
}

.btn-start{
    background-color: #007bff;
    color: #fff;
}


//para bubble background
$bubble-count: 50;
$sway-type: "sway-left-to-right", "sway-right-to-left";

@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

@function sample($list) {
  @return nth($list, random(length($list)));
}

.bubbles {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.bubble {
  position: absolute;
  left: var(--bubble-left-offset);
  bottom: -75%;
  display: block;
  width: var(--bubble-radius);
  height: var(--bubble-radius);
  border-radius: 50%;
  animation: float-up var(--bubble-float-duration) var(--bubble-float-delay) ease-in infinite;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsla(207, 63%, 46%, 0.3);
    border-radius: inherit;
    animation: var(--bubble-sway-type) var(--bubble-sway-duration) var(--bubble-sway-delay) ease-in-out alternate infinite;
  }

  @for $i from 0 through $bubble-count {
    &:nth-child(#{$i}) {
      --bubble-left-offset: #{random_range(0vw, 100vw)};
      --bubble-radius: #{random_range(1vw, 10vw)};
      --bubble-float-duration: #{random_range(6s, 12s)};
      --bubble-sway-duration: #{random_range(4s, 6s)};
      --bubble-float-delay: #{random_range(0s, 1s)};
      --bubble-sway-delay: #{random_range(0s, 1s)};
      --bubble-sway-type: #{sample($sway-type)};
    }
  }
}

@keyframes float-up {
  to {
    transform: translateY(-175vh);
  }
}

@keyframes sway-left-to-right {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes sway-right-to-left {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

// END MODAL INIT WIZARD


//Overlay effect

.modal-spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Fondo semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Asegura que esté por encima del modal */
}

.spinner-border {
  width: 4rem;
  height: 4rem;
}

.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .question-mark {
    cursor: pointer;
    text-decoration: none;
    color: #333;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ccc;
  }
  
  .tooltip-text {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    border-radius: 8px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: calc(100% + 10px); /* Espacio entre el tooltip y el elemento */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    text-align: center;
    max-height: 150px !important;
    transition: opacity 0.3s, visibility 0s linear 0.3s;
    width: 250px !important;
  }
  
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  
  .input-with-tooltip {
    position: relative;
  }
  

  @media (max-width: 767px) {
    .tooltip-text {
      left: 50%;
      transform: translateX(-100%);
      bottom: 10px; /* Ajuste la posición vertical según sea necesario */
      top: unset !important; /* Asegura que la posición vertical no esté fijada */
    }
  }
  
.result-box{
    display: flex;
    flex-direction: column;
    box-shadow: 3px 2px 7px 0px #8e8e8e;
    border-radius: 0 10px 10px 10px;

    .result-box__content{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 20px 10px;
        border-bottom: 1px solid rgb(177, 177, 177);
        .result-box-content__init{
            display: flex;
            flex-direction: row;
            *{
                margin: 0 5px;
            }
        
            .result-box-content-init__desc{
                margin: auto 0;
            }
        }

    .result-box-content__rest{
        display: flex;
        flex-direction: row;
        width: auto;
        justify-content: space-between;
        
        *{
            margin: 0px 15px;
        }
    }
        
    &:hover{
        background-color: #215c9c;
        transition: 1s all ease;
        color: #fff !important;
        cursor: pointer;
        .result-box-content-init__desc{
            color: #fff !important;
        }
        width:100%;
        border: none !important;
    }
    }

}

.info-box {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 4px;
    background-color: #f8f8f8;
    margin-top: 25px;

.close-button {
    font-size: 16px;
    margin-bottom: 5px;
    margin-left: 95%;
    color: #787878;
    cursor: pointer;
    transition: 0.3s all ease; 
    
    &:hover{
        color: #fff;
        background-color: #215c9c;
        padding: 4px 9px;
        border-radius: 5px;
        transition: 0.3s all ease;
    }
  }
  

}

.custom-svg {
    width: 35%; /* Ajusta el ancho según tu preferencia */
    height: auto; /* Mantiene la proporción original */
}

.search-i{
    width: 20px;
    height: auto;
}

.mat-box{
    background-color: #3b7bc0;
    padding: 20px;
    border-radius: 25px;
    transition: 1s all ease;
    .mat-box__title{
        color: #fff;
        font-size: 20px;
    }

    .mat-box__img{
        height: auto;
        width: 20%;
        margin-left: auto;
    }

    &:hover{
        background-color: #1a3450;
        transition: 1s all ease;
        cursor: pointer;
    }
}

@media (max-width: 767px) {
    .row {
      flex-direction: column; /* Cambia la dirección de flexbox a columna */
    }
    .text-center {
      text-align: center; /* Centra el texto */
    }
    .image-container {
      order: -1; /* Cambia el orden de visualización de la imagen (arriba) */
    }
  }

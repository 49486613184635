.user-action{
    position: relative;
    border-radius: 10px;
    z-index: 2;
    
    .box-title{
      h4{
        color: #333;
        display: inline-block;
        background: #ffffff;
        border-radius: 5px;
        padding: 10px;
      }
  
      .box-descriptive{
        display: flex;
        flex-direction: column;
        margin-top: auto;
        justify-content: center;
        margin-top: 10px;
        .box-title__item_descriptive{
          color: #fff;
          padding: 5px 0;
        }
        span{
          font-size: 14px !important;
        }
      }
  
    z-index: 2;
    }
  
    .box-img{
      z-index: 2;
    }
  
    
    .inscribirme-text {
      color: #fff;
      font-size: 15px;
      font-weight: bold;
      margin-right: 10px;
      display: inline-block;
      z-index: 2;
  
    }
  
    &:hover{
      cursor: pointer;
  
      .box-title{
        transform: translateY(-8px);
        transition: 0.5s all ease;
      }
  
  
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 15px;
        transform: translate(-50%, -50%);
        background-color: #a96823;
        border-radius: 50%;
        animation: bckgExpand 0.3s ease-in-out forwards;
        z-index: 1;
      }
    }
}

.user_create{
    background: #025692;
}

.user_delete{
    background: #e2504c;
}
.logo-animation {
    transition: 1s all ease-in-out;
    animation: none;

    &:hover{
        animation: logoJumpUp 4s ease-in-out infinite;
        transition: none;
    }
}

@keyframes logoJumpUp {
    0%, 100% {
        transform: translateY(0);
    }
    25%, 75% {
        transform: translateY(-10px);
    }
    50% {
        transform: translateY(0);
    }
}

//
// Bullet
//

// Variables
$bullet-border-radius: 2rem;
$bullet-hor-width: 10px;
$bullet-hor-height: 2px;
$bullet-ver-width: 2px;
$bullet-ver-height: 10px;
$bullet-dot-size: 4px;
$bullet-dot-size: 4px;
$bullet-bar-width: 4px;

// Base
.bullet {
    // Base
    display: inline-block;
    background-color: $gray-300;
    width: $bullet-hor-width;
    height: $bullet-hor-height;
    @include border-radius($bullet-border-radius);

    // Dot bullet
    &.bullet-dot {
        width: $bullet-dot-size;
        height: $bullet-dot-size;
        border-radius: 100% !important;
    }

    // Vertical bullet
    &.bullet-ver {
        width: $bullet-ver-width;
        height: $bullet-ver-height;
    }

    // Vertical resizable bullet(use with align-self-stretch)
    &.bullet-bar {
        width: $bullet-bar-width;
        height: auto;
    }
}

.pdf-box{
    padding: 15px;
    height: calc(100%+10%);
    border: 2px dashed #215c9c;
    background-color: #86c0ff3d;
    margin: 4px 5px;

    .pdf-box__deleter{
        display:inline-block;
        position: absolute;
        top: 13px;
        right: 13px;
        background-color: #215c9c;
        padding: 1px;
        
        &:hover{
            cursor: pointer;
        }
    }

    .pdf-box__content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .pdf-box-content__title{
            color: #215c9c;
            font-size: 1.3em;
        }
    }

    &:hover{
        background-color: #335c88;
        transition: 1s all ease;
        border: 2px dashed #fff;
        .pdf-box__content{
            .pdf-box-content__title{
                color: #fff;
            }

            svg{
                fill: #fff;
            }
        }
    }
}